import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/pageheader"
import NewsItem from "../components/newsItem"
import * as styles from "../styles/news.module.css"
import { Link } from "gatsby"
import { useLangContext } from "../context/langContext"
import { shuffleArray } from "../utils/utils"

const NewsEventsSinglePage = ({ pageContext, location }) => {
    const { lang } = useLangContext()
    const basicPath = pageContext?.basicPath
    let data = pageContext?.data?.node
    if (lang === "id" && data?.translations.length > 0) {
        data = data?.translations[0]
    }
    const seo = data?.seo
    const featuredImageData = data?.featuredImage?.node
    const coverMain = data?.blocks.find(
        v => v.name == "acf/cover-main"
    )?.coverMain
    const relatedNews = shuffleArray(
        pageContext?.relatedNews?.posts?.nodes.filter(
            p => p?.slug != data?.slug
        )
    )

    return (
        <Layout isTestimonialsHidden activeMenuId={5}>
            <Seo
                title={data?.title}
                description={seo?.opengraphDescription}
                date={data?.plainDate}
                img={featuredImageData?.mediaItemUrl}
                imgHeight={featuredImageData?.mediaDetails?.height}
                imgWidth={featuredImageData?.mediaDetails?.width}
            />
            <PageHeader
                image={coverMain?.img?.mediaItemUrl}
                breadcrumbs={[
                    lang === "en"
                        ? { label: "News & Events", link: basicPath }
                        : { label: "Berita & Acara", link: basicPath },
                    {
                        label: data?.title,
                        link: null,
                    },
                ]}
            >
                <div className="container mx-auto">
                    <div
                        className={`mx-auto flex lg:justify-between lg:items-start flex-col lg:flex-row  ${styles.pageHeader}`}
                    >
                        <div className={`flex-1 ${styles.singleContent}`}>
                            <div>
                                <Link className={styles.singleCategory} to="#">
                                    {data?.categories?.nodes.map((v, i) => {
                                        if (i > 0) {
                                            return `,${v.name}`
                                        } else {
                                            return v.name
                                        }
                                    })}
                                </Link>
                            </div>
                            <h1
                                className={`font-serif text-4xl lg:text-6xl my-4 ${styles.singleTitle}`}
                            >
                                {data?.title}
                            </h1>
                            <span className={styles.singleDate}>
                                {data?.date}
                            </span>
                        </div>
                        <div
                            className={`mt-8 lg:mt-0 w-full lg:w-auto flex justify-start lg:justify-end items-center ${styles.socmedShare}`}
                        >
                            <div>
                                {lang === "en"
                                    ? "Share this article"
                                    : "Bagikan artikel ini"}
                            </div>
                            <ul>
                                {location.href && (
                                    <>
                                        <li
                                            className={`${styles.socmedIcon} ${styles.socmedWa}`}
                                        >
                                            <a
                                                href={`https://api.whatsapp.com/send?phone=&text=${location.href}`}
                                                target="_blank"
                                            >
                                                Whatsapp
                                            </a>
                                        </li>
                                        <li
                                            className={`${styles.socmedIcon} ${styles.socmedFb}`}
                                        >
                                            <a
                                                href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                                                target="_blank"
                                            >
                                                Facebook
                                            </a>
                                        </li>
                                        <li
                                            className={`${styles.socmedIcon} ${styles.socmedTw}`}
                                        >
                                            <a
                                                href={`https://twitter.com/share?url=${location.href}`}
                                                target="_blank"
                                            >
                                                Twitter
                                            </a>
                                        </li>
                                        <li
                                            className={`${styles.socmedIcon} ${styles.socmedMl}`}
                                        >
                                            <a
                                                href={`mailto:?subject=${data?.title}&body=${location.href}`}
                                                target="_blank"
                                            >
                                                Mail
                                            </a>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </PageHeader>
            <div className="bg-white py-4">
                <div className="container mx-auto">
                    <div className={`mx-auto ${styles.singleContent}`}>
                        {data?.blocks.map((item, i) => {
                            switch (item?.name) {
                                // case "acf/cover-main":
                                //     const coverMain = item?.coverMain
                                //     return (
                                //         <div key={i}>
                                //             <img
                                //                 src={
                                //                     coverMain?.img?.mediaItemUrl
                                //                 }
                                //                 alt={coverMain?.img?.altText}
                                //             />
                                //         </div>
                                //     )
                                case "core/paragraph":
                                    return (
                                        <div
                                            key={i}
                                            className={`px-4 lg:px-0 ${styles.textWrap}`}
                                        >
                                            <p
                                                className={`${styles.postContent}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.attributes
                                                        ?.content,
                                                }}
                                            ></p>
                                        </div>
                                    )
                                case "core/media-text":
                                    return (
                                        <div
                                            key={i}
                                            className={`px-4 lg:px-0 ${styles.textWrap}`}
                                        >
                                            <div className="float-left mr-8 mb-8">
                                                <img
                                                    src={
                                                        item?.attributes
                                                            ?.mediaUrl
                                                    }
                                                    alt={
                                                        item?.attributes
                                                            ?.mediaAlt
                                                    }
                                                />
                                            </div>
                                            {item?.innerBlocks.map((v, k) => {
                                                if (
                                                    v?.name === "core/paragraph"
                                                ) {
                                                    return (
                                                        <p key={k}>
                                                            {
                                                                v?.attributes
                                                                    ?.content
                                                            }
                                                        </p>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )
                                case "acf/acfimage":
                                    return (
                                        <div
                                            key={i}
                                            className={`px-4 lg:px-0 ${styles.textWrap}`}
                                        >
                                            <img
                                                src={
                                                    item?.imageBlock?.img
                                                        ?.sourceUrl
                                                }
                                                alt={
                                                    item?.imageBlock?.img
                                                        ?.altText
                                                }
                                            />
                                            {item?.imageBlock?.img?.caption && (
                                                <div
                                                    className="text-sm mt-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.imageBlock
                                                            .img.caption,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )
                                case "core/embed":
                                    return (
                                        <div
                                            key={i}
                                            className={`px-4 lg:px-0 ${styles.textWrap}`}
                                        >
                                            <div className="mb-8">
                                                <div className={styles.videoFrame}>
                                                    <iframe
                                                        className="absolute top-0 left-0 w-full h-full"
                                                        src={item?.attributes?.url}
                                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                                        title="Kota Wisata"
                                                        frameBorder="0"
                                                        webkitallowfullscreen="true"
                                                        mozallowfullscreen="true"
                                                        allowFullScreen
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        })}
                    </div>
                    <div className={`px-4 pt-4 ${styles.relatedBox}`}>
                        <h2
                            className={`font-serif text-4xl mt-8 mb-4 ${styles.singleRelated}`}
                        >
                            {lang === "en"
                                ? "Other Articles"
                                : "Berita Lainnya"}
                        </h2>
                        <div className="-mx-4 mb-8">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0">
                                {relatedNews.slice(0, 5).map((post, k) => {
                                    if (post?.slug !== data?.slug) {
                                        return (
                                            <NewsItem
                                                key={k}
                                                title={post?.title}
                                                link={`${basicPath}/${post?.slug}`}
                                                date={post?.date}
                                                image={
                                                    post?.featuredImage?.node
                                                        ?.mediaItemUrl
                                                }
                                                imageAlt={
                                                    post?.featuredImage?.node
                                                        ?.altText
                                                }
                                            />
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NewsEventsSinglePage
