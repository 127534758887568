// extracted by mini-css-extract-plugin
export var pagination = "news-module--pagination--NTG4B";
export var pageItem = "news-module--pageItem--95uIN";
export var prev = "news-module--prev--XdiSe";
export var next = "news-module--next--3UClf";
export var singleCategory = "news-module--singleCategory--1IBH6";
export var singleDate = "news-module--singleDate--3dNcN";
export var singleTitle = "news-module--singleTitle--3TLiB";
export var pageHeader = "news-module--pageHeader--2gmch";
export var singleContent = "news-module--singleContent--yiYJ9";
export var socmedShare = "news-module--socmedShare--21V2H";
export var socmedIcon = "news-module--socmedIcon--3GyY2";
export var socmedWa = "news-module--socmedWa--3dO8I";
export var socmedFb = "news-module--socmedFb--Uwm7w";
export var socmedTw = "news-module--socmedTw--3rJEN";
export var socmedMl = "news-module--socmedMl--1zJr4";
export var textWrap = "news-module--textWrap--HgzOG";
export var relatedBox = "news-module--relatedBox--2rfvq";
export var singleRelated = "news-module--singleRelated--TF-XM";
export var postContent = "news-module--postContent--3ejnY";
export var videoFrame = "news-module--videoFrame--1_Pni";